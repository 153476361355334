import { clsMerge } from "@artifactlabs/shared-react-utils";
import { FunctionComponent } from "react";

import TabItem from "./TabItems";

interface TabProps {
  className?: string;
  children: React.ReactNode;
  keepCentered: boolean;
}

interface TabComposition {
  TabItem: typeof TabItem;
}

const GalleryCategoryTab: FunctionComponent<TabProps> & TabComposition = ({
  className,
  children,
  keepCentered,
}) => {
  return (
    <div className={clsMerge("flex h-[61px] items-center bg-white", className)}>
      <div
        className={clsMerge(
          "scrollbar-hide overflow-y-auto",
          "flex h-full flex-auto items-center ",
          // keep the items centered on all screens, or only on large screens
          // because on mobile or smaller screens, we can't scroll to the leftmost or rightmost when it's justify-centered
          keepCentered ? "justify-center" : "lg:justify-center",
          "gap-x-[24px] lg:gap-x-[48px]",
          "px-[15px] lg:px-[40px]",
        )}
      >
        {children}
      </div>
    </div>
  );
};

GalleryCategoryTab.TabItem = TabItem;

export default GalleryCategoryTab;
