import { FunctionComponent, HTMLAttributes } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

import { GridImageItem } from "./GridImageItem";

import AppLink from "@/components/AppLink";
import Spinner from "@/components/Spinner";
import VideoPreviewer from "@/components/VideoPreviewer";
import { SearchArtworksQuery } from "@/gql/graphql";
import { getAssetContentTypeWithAssetFileFormat } from "@/utils/helpers/assetContentType";
import { routes } from "@/utils/routes";

interface Props extends HTMLAttributes<HTMLDivElement> {
  loading: boolean;
  assets: SearchArtworksQuery["searchArtworks"];
}

export const MasonryGallery: FunctionComponent<Props> = ({
  assets,
  loading,
  children,
  ...rest
}) => {
  //
  return (
    <div {...rest}>
      {loading ? (
        <div className="flex flex-1 items-center justify-center pt-6">
          <Spinner />
        </div>
      ) : (
        <>
          {(assets?.paginatedResults ?? []).length > 0 && (
            <>
              <ResponsiveMasonry
                className="w-full"
                columnsCountBreakPoints={{ 640: 2, 768: 2, 1023: 3, 1280: 4 }}
              >
                <Masonry gutter={"16px"}>
                  {assets?.paginatedResults?.map((each: any) => {
                    if (!each) {
                      return null;
                    }

                    const assetType = getAssetContentTypeWithAssetFileFormat(
                      each.metadata?.assetFileFormat ?? "",
                    );

                    if (assetType === "images" || assetType === "audios") {
                      return (
                        <GridImageItem
                          imageSrc={each.src}
                          key={each.extId}
                          navigateTo={routes.artwork(each.extId)}
                        />
                      );
                    }
                    //
                    else if (assetType === "videos") {
                      return (
                        <AppLink href={routes.artwork(each.extId)} key={each.extId}>
                          <div className="relative">
                            <VideoPreviewer
                              assetSrc={each.assetSrc}
                              className="h-full w-full rounded-[8px]"
                              duration={each.assetDuration}
                              extId={each.extId}
                              loop={true}
                              muted={true}
                              playOnMouseHover={true}
                              showControls={false}
                              showDuration={true}
                              src={each.src}
                            />
                          </div>
                        </AppLink>
                      );
                    }
                  })}
                </Masonry>
              </ResponsiveMasonry>

              {children}
            </>
          )}

          {(assets?.paginatedResults ?? []).length === 0 && (
            <div className="flex items-center justify-center pb-[40px] pt-12">
              <div className=" text-sm">No results found</div>
            </div>
          )}
        </>
      )}
    </div>
  );
};
